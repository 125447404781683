import { ReloadOutlined } from '@ant-design/icons';
import { Button, Radio, Spin } from 'antd';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { ReportLineGraph, YcTitle } from '@/components';
import { usePageTitle } from '@/hooks/use-title';
import { MControlPanel } from '@/modeles';
import { controlPanelService_SA } from '@/services';

export function SAControlPanelPage() {
  usePageTitle('Control panel');

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<MControlPanel>(new MControlPanel());
  const [mode, setMode] = useState<'7d' | '30d' | '90d'>('90d');
  const height = 200;

  const maxDiff = useMemo(() => {
    const max = data.maxYsoRevenue;
    const yn = data.ynYsoRevenue;

    return max.map((maxRow) => ({
      date: maxRow.date,
      diffPercent: (((yn.find((ynRow) => moment.utc(maxRow.date).isSame(moment.utc(ynRow.date)))?.monet || 0) / maxRow.revenue) - 1) * 100,
    }))
  }, [data])

  const fetchData = useCallback(() => {
    setLoading(true);
    controlPanelService_SA.view(mode).then((data) => {
      setData(data || new MControlPanel());
    }).finally(() => {
      setLoading(false);
    });
  }, [mode]);

  useEffect(() => { fetchData() }, [fetchData]);

  return (
    <Spin spinning={loading}>
      <div className='d-flex'>
        <YcTitle label='Control Panel' /><Button type="link" onClick={() => fetchData()}><ReloadOutlined /></Button>
      </div>
      <div className="mb-4">
        <Radio.Group onChange={(v) => setMode(v.target.value)} value={mode} optionType='button' buttonStyle="solid">
          <Radio value={'7d'}>7 days</Radio>
          <Radio value={'30d'}>30 days</Radio>
          <Radio value={'90d'}>90 days</Radio>
        </Radio.Group>
      </div>
      <div className="row mb-4">
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div>Customer UA</div>
          <ReportLineGraph data={data.ynCustomerUA} is={['account']} fields={['spend']} height={height} />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div>Customer Monetization</div>
          <ReportLineGraph data={data.ynCustomerMonet} is={['account']} fields={['revenue']} height={height} />
        </div>
      </div>
      <div className="row mb-4">
        <div className="col">
          <div>Financial traffic</div>
          <ReportLineGraph data={data.ynYsoVsCustomer} is={[]} fields={['sCustomer', 'rCustomer', 'diffYso']} height={height} />
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div>UA: Other VS YSO</div>
          <ReportLineGraph data={data.ynYsoVsCustomer} is={[]} fields={['sCustomer', 'sYsoCorp', 'sTotal']} height={height} />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div>Monet: Other VS YSO</div>
          <ReportLineGraph data={data.ynYsoVsCustomer} is={[]} fields={['rCustomer', 'rYsoCorp', 'rTotal']} height={height} />
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div>Max SOV (YSO Corp only)</div>
          <ReportLineGraph data={data.maxYsoSov} is={[]} fields={['withBanner', 'withoutBanner']} height={height} />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div>Max diff</div>
          <ReportLineGraph data={maxDiff} is={[]} fields={['diffPercent']} height={height} />
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div>ROI</div>
          <ReportLineGraph data={data.ynRoi} is={[]} fields={['roi', 'roiControl']} height={height} />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div>Total</div>
          <ReportLineGraph data={data.ynRoi} is={[]} fields={['ua', 'monet', 'profit']} height={height} />
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div>Customer ROAS D0</div>
          <ReportLineGraph data={data.ynCampaignRoas} is={[]} fields={['roasD0']} height={height} verticalLineKeys={[moment.utc()
            .subtract(2, 'days')
            .format('YYYY-MM-DD')]} />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div>Customer ROAS D7</div>
          <ReportLineGraph data={data.ynCampaignRoas} is={[]} fields={['roasD7']} height={height} verticalLineKeys={[moment.utc()
            .subtract(9, 'days')
            .format('YYYY-MM-DD')]} />
        </div>
      </div>
    </Spin>
  );
}
