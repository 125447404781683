export enum BidAbTests {
  CONTROL = 'control',
  TEST_DEVICE = 'test-device',
  NO_BID = 'no-bid',
  LEARNING = 'learning',
  NO_LEARNING = 'no-learning',

  // Testing abs
  NO_BID_FLOOR = 'no-bid-floor',
  ROI_REG_OFF = 'roi-reg-off',
  ROI_REG_10 = 'roi-reg-10',
  ROI_REG_NO_MAX = 'roi-reg-no-max',
  ROI_REG_NO_MAX_50 = 'roi-reg-no-max-50',
  DISABLE_BONUS = 'disable-bonus',
  DISABLE_BONUS_M = 'disable-bonus-m',
  DISABLE_BONUS_UA = 'disable-bonus-ua',
  LOW_ASSET_QUALITY = 'low-asset-quality',
  HIGH_ASSET_QUALITY = 'high-asset-quality',
  ORIGINAL_ASSET_QUALITY = 'original-asset-quality',
  WEBVIEW_LOAD = 'webview-load',
  SDK_LOAD = 'sdk-load',
  AD_COMPUTE_ICON_FIRST = 'ad-compute-icon-first',
  AD_COMPUTE_3M = 'ad-compute-3m',
  AD_COMPUTE_3M_NO_LEARNING = 'ad-compute-3m-no-learning',
  AD_COMPUTE_MIXED = 'ad-compute-mixed',
  AD_NO_FILTER = 'ad-no-filter',
  AD_ICON_FILTER = 'ad-icon-filter',
  IMP_X = 'imp-x',
  DIRECT_TRACK = 'direct-track',
  DIRECT_TRACK_ONLY = 'direct-track-only',
  REMOVE_BATCH_TRACK = 'remove-batch-track',
  NEXT_STEP_DIRECT_CLOSE = 'next-step-direct-close',
  MAX_BID_IR_NO_LIMIT = 'max-bid-ir-no-limit',
  TOP_CREA = 'top-crea',
  TOP_CREA_PRED = 'top-crea-pred',
  CLIENT_M_NO_MULTIPLIER = 'client-m-no-multiplier',
  ML_1M = 'ml-1m',
  ML_LE_3M = 'ml-le-3m',
  ML_ATT_MAX_1M = 'ml-att-max-1m',
  AND_AD_TYPE = 'and-ad-type',
  SWP = 'swp',
  SWP_2 = 'swp-2',
  SWP_3 = 'swp-3'
}

export default BidAbTests;
